(function () {
	'use strict';

	angular
		.module('app')
		.factory('defaultSourceConfig', ['$sce', defaultSourceConfig]);

	function defaultSourceConfig($sce) {
		return {
			apiConnection: apiConnection(),
			config: config(),
			fieldMap: fieldMap(),
			unusedMap: unusedMap(),
			allowHTMLMap: allowHTMLMap(),
		};

		function apiConnection() {
			return {
				clientID: 'APIClientID',
				calendarApiKey: 'ApiKey',
				accessScope: 'API Access Scope URLs If Required',
				authIsRedirect: false, // Boolean if we should redirect or open new window for OAuth2
			};
		}

		function config() {
			return {
				id: 13,
				name: 'Source Type Name (Google Calendar)',
				propertyName: 'sourceTypeName (googleCalendar)',
				available: true, // A boolean wether this source type should be available for users to enable
				editable: true,
				isPrimary: true,
				async: true,
				seperateSchedules: true, // Are there many calendars per sign in / account or set to false if we map an object / table to each calendar
				colorType: 'hex', // 'hex' or 'rgba'
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: './images/source-type-icon.png',
				sourceHelp: $sce.trustAsHtml(
					'There is no required "configuration" for Google Calendars; You may add custom fields or actions to your events using the tabs above.'
				),
				fieldMappingHelp: $sce.trustAsHtml(
					"<h4>You can choose the label to appear next to a field when editing an event.</h4>If you don't want a particular field to appear just un-check the show check-box beside the field. If you don't see a checkbox it means that field is required for the calendar to work."
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a>.</p>'
				),
				maxSources: 1,
				showAuthButton: true,
				authButton: './images/authorize-source-icon.png',
				eventFocus: null,
				refresh: null,
				useISOTimestamp: true,
				useDefaultFieldMap: true,
				customActionsEditable: true,

				settings: {
					//setting: the name of the setting, visible: wether or not the setting is user facing, defaultValue: the default value if any
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 10,
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						format: 'name',
						defaultValue: 'New Source Type Calendar',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(244, 244, 244, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
									<div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
							 ...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: false,
						displayValue: 'Read Only',
						defaultValue: false,
						format: 'yesno',
					},
					readOnlyNote: {
						setting: 'readOnlyNote',
						visible: true,
						displayValue: 'Read Only',
						defaultValue: '',
						helptext:
							'For Google calendars, please manage read-only attributes at the calendar and user level in <a href="https://www.google.com/calendar" target="_blank">Google Calendar</a>. Restrictions set there will be respected here.',
						format: 'note',
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: false,
						displayValue: 'Backend date format',
						defaultValue: 'YYYY-MM-DD',
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: '',
						format: 'defaultResource',
					},
				},
			};
		}

		function fieldMap() {
			return {
				title: {
					setting: 'title',
					visible: true,
					displayValue: 'Title Display Summary',
					defaultValue: 'Title, Description',
					labelValue: 'Display',
					required: true,
					unused: false,
					helptext:
						'This is the text that appears on the calendar for each event. You can combine several fields here, seperated by a comma. These fields will be seperated by carriage returns in the Schedule views. You can specify which string to substitute for the carriage return in the other views in "Event Styles."' +
						'<p><strong>Standard field names that can be referenced are:</strong>' +
						'<ul>' +
						'<li>Title</li>' +
						'<li>Description</li>' +
						'<li>AllDay</li>' +
						'<li>Start</li>' +
						'<li>End</li>' +
						'<li>Location</li>' +
						'<li>Resource</li>' +
						'<li>Status</li>' +
						'</ul>' +
						'Any custom field available to the calendar may also be referenced using the ID or the field name assigned to that custom field.' +
						'</p>' +
						'<p>' +
						'Styling adjustments can be made with a special "dbk-css" tag. Wrapping fields in this tag will allow inline styling or CSS classes.' +
						'</p>' +
						'<div class="exampleCode">' +
						'&lt;dbk-css style="color: red;"&gt;Title&lt;/dbk-css&gt;, &lt;dbk-css class="large-text"&gt;Description&lt;/dbk-css&gt;' +
						'</div>' +
						'<br />' +
						'<p>You’ll find more instructions on styling events with CSS <a href="https://docs.dayback.com/article/111-event-styles" target="_blank">here</a>.</p>',
				},
				eventID: {
					setting: 'eventID',
					visible: false,
					displayValue: 'Event id',
					defaultValue: 'eventID',
					required: true,
					unused: false,
					helptext:
						'The primary key or unique ID in your table. For all the fields that follow, use <i>just</i> your field name, not the table and field name. So if your field was called "id" you\'d enter id above, not MyTable::id',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: true,
					displayValue: 'Title',
					defaultValue: 'titleEdit',
					labelValue: 'Enter Title',
					required: true,
					unused: false,
					helptext: '',
				},
				description: {
					setting: 'description',
					visible: true,
					displayValue: 'Description',
					defaultValue: 'description',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext: '',
				},
				repeat: {
					setting: 'repeat',
					visible: true,
					uiOnly: true,
					displayValue: 'Repeat',
					defaultValue: '',
					labelValue: 'Repeat',
					required: false,
					unused: false,
					helptext: '',
				},
				allDay: {
					setting: 'allDay',
					visible: true,
					displayValue: 'AllDay',
					defaultValue: 'allDay',
					labelValue: 'All Day',
					required: false,
					unused: false,
					helptext: '',
				},
				start: {
					setting: 'start',
					visible: true,
					displayValue: 'Start',
					defaultValue: 'start',
					labelValue: 'Start',
					required: true,
					unused: false,
					helptext: '',
				},
				end: {
					setting: 'end',
					visible: true,
					displayValue: 'End',
					defaultValue: 'end',
					labelValue: 'End',
					required: false,
					unused: false,
					helptext: '',
				},
				calendar: {
					setting: 'calendar',
					visible: true,
					uiOnly: true,
					displayValue: 'Calendar',
					defaultValue: '',
					labelValue: 'Calendar',
					required: false,
					unused: false,
					helptext: '',
				},
				location: {
					setting: 'location',
					visible: true,
					displayValue: 'Location',
					defaultValue: 'location',
					labelValue: 'Location',
					required: false,
					unused: false,
					helptext: '',
				},
				resource: {
					setting: 'resource',
					visible: true,
					displayValue: 'Resource',
					defaultValue: 'resource',
					labelValue: 'Resource',
					required: false,
					unused: false,
					helptext: '',
				},
				status: {
					setting: 'status',
					visible: true,
					displayValue: 'Status',
					defaultValue: 'status',
					labelValue: 'Status',
					required: false,
					unused: false,
					helptext: '',
				},
				tags: {
					setting: 'tags',
					visible: false,
					displayValue: 'Tags',
					defaultValue: 'tags',
					labelValue: 'Tags',
					required: false,
					unused: false,
					helptext: '',
				},
				attendees: {
					setting: 'attendees',
					visible: false,
					displayValue: 'Attendees',
					labelValue: 'Attendees',
					defaultValue: 'attendees',
					required: false,
					unused: false,
					helptext: 'List of attendees if any exist.',
				},
				additionalDayBackData: {
					setting: 'additionalDayBackData',
					visible: false,
					displayValue: 'additionalDayBackData',
					defaultValue: '{}',
					labelValue: 'additionalDayBackData',
					required: false,
					unused: false,
					helptext: '',
				},
				customFields: {
					setting: 'customFields',
					visible: true,
					uiOnly: true,
					displayValue: 'Custom Fields',
					defaultValue: '',
					labelValue: 'Custom Fields',
					required: false,
					unused: false,
					helptext: '',
				},
			};
		}

		function unusedMap() {
			return {
				contactID: true,
				contactName: true,
				projectID: true,
				projectName: true,
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {
				description: true,
			};
		}
	}
})();
